@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
$primary: #000; /* MAIN COLOR */
$secondary: red; /* SECONDARY COLOR */
$blk: #333333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
html,body {
  height: 100%;

  /*margin: 0;
  padding: 0;
  overflow: auto;*/
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.8em;
  @media(max-width: 767px){
    font-size: 1.5em;
  }

}
h1,h2,h3,h4,h5,h6 {
  font-size:1.5em;
  @media(max-width: 767px){
    font-size: 1.2em;
  }
}
.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;
  background: $primary;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 36px;
			display: flex;
			align-items: center;
			color: $wht;

			@media (max-width: 767px) {
				  margin-top: 0;
				  padding: 6px 0px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $wht;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $wht;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: none;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1em 2em;
	font-size: .8em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	margin: 1em 0em;
	transition: 0.7s ease all;

	&:hover {
    background: $secondary;
  	color: $wht;
		text-decoration: none;

			@media (max-width: 1024px) {
        background: $primary;
      	color: $wht;
			}
	}
}

.btn-default {
	@include btn;
}

@mixin btn2 {
  border: none;
  border-radius: $border-radius;
  background: $secondary;
  color: $wht;
  padding: 1em 2em;
  font-size: .8em;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  margin: 1em 0em;
  transition: 0.7s ease all;

  &:hover {
    background: darken($secondary, 30%);
    color: $wht;
    text-decoration: none;

      @media (max-width: 1024px) {
        background: $secondary;
        color: $wht;
      }
  }
}

.btn-default2 {
  @include btn2;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 100%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $secondary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/


// sections start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  @media(max-width: 991px){
      padding:60px 0;
  }
  @media(max-width: 767px){
      padding:40px 0;
  }
  img{
    box-shadow: 5px 5px 1px $secondary;
  }
}
.section{
  padding: 300px 0;
  background-image: url('../img/bg1.jpg');
  color: $wht;
  background-position: 0 0;
  @media(max-width: 991px){
    padding: 150px 0;
  }
  @media(max-width: 767px){
    padding: 100px 0;
  }
  @media(max-width:600px){
    padding: 70px 0;
  }
  @media(max-width:450px){
    padding: 30px 0;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    letter-spacing: 10px;
    text-transform: uppercase;
    @media(max-width: 991px){
      font-size: 1.7em;
    }
    @media(max-width:767px){
      font-size: 1.4em;
    }
  }
  p {
    font-size: 1.5em;
    font-style: italic;
    @media(max-width: 767px){
        line-height: 1em;
        font-size: 1.2em;

    }
  }
}
.section-a {
  color: $wht;
  background-color: $primary;
  h2 {
    letter-spacing: 1px;
    font-size: 1.7em;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    @media(max-width: 991px){
      margin-top: 10%;
      font-size: 1.4em;
    }
  }
  p{
    font-size: 1.1em;
    text-align: center;
  }
}
.section-b {
  background-color: $secondary;
  color: $wht;
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    line-height: 1.8em;

    @media(max-width: 767px){
      font-size: 1em;
      line-height: 1.5em;
    }
  }

}
.section-c{
  h2 {
    margin-top: 15%;
    text-transform: uppercase;
    @media(max-width: 991px){
      margin-top: 0;
    }
  }
  img{
    margin-bottom: 25px;
  }
}
.section-d {
  color: $blk;
  background-color: darken($wht,5%);
  font-size: 1.2em;
  @media(max-width: 480px){
    text-align: center;
    font-size: 1em;
  }

  i{
    @media(max-width: 480px){
      width: 100%;
      text-align: center;
    }
  }

  .footer-banner {
    border-left: 10px solid $blk;
    padding-left: 50px;
    @media(max-width: 991px){
      border-top: 2px solid $blk;
      border-left: none;
      padding-left: 20px;
      padding-top: 20px;
    }
  }

  ul {
    padding: 0;

    li {
      line-height: 2em;
      list-style: none;
      @media(max-width: 480px){
        text-align: center;
      }
      i{
        padding-right: 20px;
      }

      a{
        color: $blk;
      }
    }
  }
}
// sections end

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}





select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
  .logo {
    max-width: 125px;
  }

  .navbar-toggle {
    margin-top: 5px;
  }

}


.top-pad {
	padding: 3em;
	background: white;
}


.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}
